<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<FilterDrawer :selectedTab="selectedTab" />
		<div>
			<h3>Commission & Allocation Management</h3>
			<a-tabs v-model="selectedTab" size="large" :key="$store.state.inventory.newTime" >
				<!-- Lots Tab -->
				<a-tab-pane v-if="instance.productType === 'lowrise'" key="lots" tab="Lots">
					<template>
						<div class="dF aC jSB">
							<div class="dF aC jSB">
								<a-checkbox :indeterminate="indeterminateLots" :checked="checkAllLots"
									@change="toggleAllSelection($event, lots, 'selectedLots', 'indeterminateLots', 'checkAllLots')" class="mr-2" />
								<h5 style="margin-bottom:0" class="mr-3">Lots</h5>
								<div class="dF" style="flex-wrap: wrap;">
									<div v-for="(status) in statuses" :key="status.value" :style="status.value != 'total'
											? `background-color:${status.color};`
											: 'border:1px solid #D0C9D6'
										" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
										<a-checkbox @change="changeSelect(status.value)"
											:checked="selectType.includes(status.value)" :style="status.value != 'total' && status.value != 'hold'
													? 'color:#FFF'
													: ''
												">{{ status.select }}</a-checkbox>
									</div>
								</div>
							</div>
							<div class="dF aC jSB">
								<a-dropdown :trigger="['click']" v-if="selectedLots.length != 0">
									<a-button style="margin-left: 8px"> Bulk Actions <a-icon type="down" /> </a-button>
									<a-menu slot="overlay" @click="(e) => onBulkAction(e.key, selectedLots, 'lot')">
										<a-menu-item key="assign"> Bulk Assign </a-menu-item>
										<a-menu-item key="unassign"> Bulk Unassign </a-menu-item>
										<a-menu-item key="commission" v-if="isLotCommissionVisible"> Bulk Commission </a-menu-item>
									</a-menu>
								</a-dropdown>
								<div @click="openFilter" class="dF aC px-3 py-1 mx-2"
									style="border-radius: 3px; background-color: #fff; cursor: pointer; border: 1px solid #ecebed;">
									<div>Filters</div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-filter ml-2">
										<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
									</svg>
								</div>
							</div>
						</div>
						<div class="dF mt-3" style="gap: 2rem; flex-direction: column;">
							<div v-for="(lot, index) in lots" :key="lot.id + $store.state.inventory.newTime">
								<div class="display-card py-3 px-3">
									<div class="dF aC jSB" style="justify-content:space-between">
										<div id="left" v-if="showScrollArrows" @click="scrollLeft(lot.id)"><a-icon
												type="left" /></div>
										<div :id="lot.id" :key="lot.id + $store.state.inventory.newTime"
											:class="index === 0 ? 'price-content price-div' : 'price-content'">
											<a-checkbox class="pr-3" @change="toggleSelection(lot.id, lots, 'selectedLots', 'indeterminateLots', 'checkAllLots')"
												:checked="selectedLots.includes(lot.id)" />
											<div class="pr-3" style="min-width:60px; width:100px">
												<div style="color:#9EA0A5">Lot #</div>
												<div>{{ lot.name }}{{ lot.block ? ` (Block ${lot.block})` : '' }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Frontage</div>
												<div>{{ getFrontage(lot.id) }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Model Type</div>
												<div>{{ getModelType(lot.id) }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Status</div>
												<div>{{ salesStatus[lot.status] }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Commission</div>
												<div v-if="isLotCommissionVisible">
													<div
														v-if="lot.commissionStructure && lot.commissionStructure.commissionAmount">
														{{ lot.commissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ lot.commissionStructure.commissionAmount.toLocaleString() }}
														{{
															lot.commissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div
														v-else-if="lotGeneralCommissionStructure && lotGeneralCommissionStructure.commissionAmount">
														{{ lotGeneralCommissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ lotGeneralCommissionStructure.commissionAmount.toLocaleString() }}
														{{
															lotGeneralCommissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div v-else> N/A</div>
												</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Assignee</div>
												<div v-if="lot.allocatedTo">{{ lot.allocatedTo.firstName + " " + lot.allocatedTo.lastName }}</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Expiration Date</div>
												<div v-if="lot.allocationExpiresAt">{{ formatDate(lot.allocationExpiresAt)}}</div>
												<div v-else>N/A</div>
											</div>
											<div class="pr-3" style="min-width:160px; width:320px">
												<div class="dF aC jSB" style="gap: 10px">
													<a-button type="primary" ghost
														@click="onCommissionUpdate(lot, 'lot')" :disabled="!isLotCommissionVisible">
														Commission
													</a-button>
													<a-button v-if="!lot.allocatedTo" :disabled="lot.status !== 'available'" type="primary" ghost
														@click="onAssign(lot, 'lot')">
														Assign
													</a-button>
													<a-button v-else :disabled="lot.status !== 'allocated'" type="primary" ghost @click="onUnassignLot(lot)">
														Unassign
													</a-button>
												</div>
											</div>
										</div>
										<div id="right" v-if="showScrollArrows" @click="scrollRight(lot.id)"><a-icon
												type="right" /></div>
									</div>
								</div>
							</div>
							<div v-if="!lots.length">
								<EmptyApp :text="'No lots found'"
									:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
							</div>
						</div>
					</template>
				</a-tab-pane>
				<!-- Units Tab -->
				<a-tab-pane v-if="instance.productType === 'highrise'" key="units" tab="Units">
					<template>
						<div class="dF aC jSB">
							<div class="dF aC jSB">
								<a-checkbox :indeterminate="indeterminateUnits" :checked="checkAllUnits"
									@change="toggleAllSelection($event, units, 'selectedUnits', 'indeterminateUnits', 'checkAllUnits')" class="mr-2" />
								<h5 style="margin-bottom:0" class="mr-3">Units</h5>
								<div class="dF" style="flex-wrap: wrap;">
									<div v-for="(status) in statuses" :key="status.value" :style="status.value != 'total'
											? `background-color:${status.color};`
											: 'border:1px solid #D0C9D6'
										" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
										<a-checkbox @change="changeSelect(status.value)"
											:checked="selectType.includes(status.value)" :style="status.value != 'total' && status.value != 'hold'
													? 'color:#FFF'
													: ''
												">{{ status.select }}</a-checkbox>
									</div>
								</div>
							</div>
							<div class="dF aC jSB">
								<a-dropdown :trigger="['click']" v-if="selectedUnits.length != 0">
									<a-button style="margin-left: 8px"> Bulk Actions <a-icon type="down" /> </a-button>
									<a-menu slot="overlay" @click="(e) => onBulkAction(e.key, selectedUnits, 'unit')">
										<a-menu-item key="assign"> Bulk Assign </a-menu-item>
										<a-menu-item key="unassign"> Bulk Unassign </a-menu-item>
										<a-menu-item key="commission" v-if="isUnitCommissionVisible"> Bulk Commission </a-menu-item>
									</a-menu>
								</a-dropdown>
								<div @click="openFilter" class="dF aC px-3 py-1 mx-2"
									style="border-radius: 3px; background-color: #fff; cursor: pointer; border: 1px solid #ecebed;">
									<div>Filters</div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-filter ml-2">
										<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
									</svg>
								</div>
							</div>
						</div>
						<div class="dF mt-3" style="gap: 2rem; flex-direction: column;">
							<div v-for="(unit, index) in units" :key="unit.id + $store.state.inventory.newTime">
								<div class="display-card py-3 px-3">
									<div class="dF aC jSB" style="justify-content:space-between">
										<div id="left" v-if="showScrollArrows" @click="scrollLeft(unit.id)"><a-icon
												type="left" /></div>
										<div :id="unit.id"
											:class="index === 0 ? 'price-content price-div' : 'price-content'">
											<a-checkbox class="pr-3" @change="toggleSelection(unit.id, units, 'selectedUnits', 'indeterminateUnits', 'checkAllUnits')"
												:checked="selectedUnits.includes(unit.id)" />
											<div class="pr-3" style="min-width:60px; width:100px">
												<div style="color:#9EA0A5">Unit #</div>
												<div>{{ unit.unitNumber }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Marketing Name</div>
												<div>{{ unit.name }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Interior</div>
												<div>{{ unit.packages[0].sqft && unit.packages[0].sqft.toLocaleString() }}
													Sq.Ft.
												</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Exterior</div>
												<div>{{ unit.packages[0].extSqft &&
													unit.packages[0].extSqft.toLocaleString() }}
													Sq.Ft.</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Exposure</div>
												<div>{{ unit.packages[0].exposure == 'north' ? 'N' :
													unit.packages[0].exposure
														==
														'south' ? 'S' : unit.packages[0].exposure == 'east' ? 'E' :
														unit.packages[0].exposure == 'west' ? 'W' : unit.packages[0].exposure ==
															'northEast' ? 'N/E' : unit.packages[0].exposure == 'southEast' ? 'S/E' :
															unit.packages[0].exposure == 'southWest' ? 'S/W' :
																unit.packages[0].exposure
																	==
																	'northwest' ? 'N/W' : 'N/A' }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Status</div>
												<div>{{ salesStatus[unit.salesStatus] }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Commission</div>
												<div v-if="isUnitCommissionVisible">
													<div
														v-if="unit.commissionStructure && unit.commissionStructure.commissionAmount">
														{{ unit.commissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ unit.commissionStructure.commissionAmount.toLocaleString() }}
														{{
															unit.commissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div
														v-else-if="unitGeneralCommissionStructure && unitGeneralCommissionStructure.commissionAmount">
														{{ unitGeneralCommissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ unitGeneralCommissionStructure.commissionAmount.toLocaleString() }}
														{{
															unitGeneralCommissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div v-else> N/A</div>
												</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Assignee</div>
												<div v-if="unit.allocatedTo">{{ unit.allocatedTo.firstName + " " +
													unit.allocatedTo.lastName }}</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Expiration Date</div>
												<div v-if="unit.allocationExpiresAt">{{ formatDate(unit.allocationExpiresAt)
												}}
												</div>
												<div v-else>N/A</div>
											</div>
											<div class="pr-3" style="min-width:160px; width:320px">
												<div class="dF aC jSB" style="gap: 10px">
													<a-button type="primary" ghost
														@click="onCommissionUpdate(unit, 'unit')" :disabled="!isUnitCommissionVisible">
														Commission
													</a-button>
													<a-button v-if="!unit.allocatedTo" :disabled="unit.salesStatus !== 'available'" type="primary" ghost
														@click="onAssign(unit, 'unit')">
														Assign
													</a-button>
													<a-button v-else :disabled="unit.salesStatus !== 'allocated'" type="primary" ghost @click="onUnassignUnit(unit)">
														Unassign
													</a-button>
												</div>
											</div>
										</div>
										<div id="right" v-if="showScrollArrows" @click="scrollRight(unit.id)"><a-icon
												type="right" /></div>
									</div>
								</div>
							</div>
							<div v-if="!units.length">
								<EmptyApp :text="'No units found'"
									:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
							</div>
						</div>
					</template>
				</a-tab-pane>
				<!-- AddOns Tabs -->
				<a-tab-pane v-if="instance.productType === 'highrise'" key="parking" tab="Parking Spots">
					<template>
						<div class="dF aC" style="justify-content:space-between">
							<div class="dF aC">
								<a-checkbox :indeterminate="indeterminateParking" :checked="checkAllParking"
									@change="toggleAllSelection($event, parkingAddOns, 'selectedParking', 'indeterminateParking', 'checkAllParking')" class="mr-3" />
								<h5 style="margin-bottom:0" class="mr-3">Parking Spots</h5>
								<div class="dF" style="flex-wrap: wrap;">
									<div v-for="(status) in statuses" :key="status.value" :style="status.value != 'total'
											? `background-color:${status.color};`
											: 'border:1px solid #D0C9D6'
										" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
										<a-checkbox @change="changeSelect(status.value)"
											:checked="selectType.includes(status.value)" :style="status.value != 'total' && status.value != 'hold'
													? 'color:#FFF'
													: ''
												">{{ status.select }}</a-checkbox>
									</div>
								</div>
							</div>
							<div class="dF aC">
								<a-dropdown :trigger="['click']" v-if="selectedParking.length != 0 && $p >= 40">
									<a-button style="margin-left: 8px"> Bulk Actions <a-icon type="down" /> </a-button>
									<a-menu slot="overlay" @click="(e) => onBulkAction(e.key, selectedParking, 'parking')">
										<a-menu-item key="assign"> Bulk Assign </a-menu-item>
										<a-menu-item key="unassign"> Bulk Unassign </a-menu-item>
										<a-menu-item key="commission" v-if="isAddOnCommissionVisible"> Bulk Commission </a-menu-item>
									</a-menu>
								</a-dropdown>
								<div @click="openFilter" class="dF aC px-3 py-1 mx-2"
									style="border-radius: 3px; background-color: #fff; cursor: pointer; border: 1px solid #ecebed;">
									<div>Filters</div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-filter ml-2">
										<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
									</svg>
								</div>
							</div>
						</div>
						<div class="dF mt-3" style="gap: 2rem; flex-direction: column;" v-if="parkingAddOns && parkingAddOns.length">
							<div v-for="(addon, addonI) in parkingAddOns" :key="addon.id + addonI">
									<div class="display-card py-3 px-3">
										<div class="dF jSB" style="justify-content:space-between">
											<a-checkbox class="pr-3 pt-1" @change="toggleSelection(addon.id, parkingAddOns, 'selectedParking', 'indeterminateParking', 'checkAllParking')"
												:checked="selectedParking.includes(addon.id)" />
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Parking ID</div>
												<div>{{ addon.pID }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Parking Spot #</div>
												<div>{{ addon.name }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Status</div>
												<div>{{ salesStatus[addon.status] }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Commission</div>
												<div v-if="isAddOnCommissionVisible">
													<div
														v-if="addon.commissionStructure && addon.commissionStructure.commissionAmount">
														{{ addon.commissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addon.commissionStructure.commissionAmount.toLocaleString() }}
														{{
															addon.commissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div
														v-else-if="addOnGeneralCommissionStructure && addOnGeneralCommissionStructure.commissionAmount">
														{{ addOnGeneralCommissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addOnGeneralCommissionStructure.commissionAmount.toLocaleString() }}
														{{
															addOnGeneralCommissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div v-else> N/A</div>
												</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Assignee</div>
												<div v-if="addon.allocatedTo">{{ getAssigneeName(addon.allocatedTo) }}</div>
												<div v-else> N/A</div>
											</div>
										<div class="pr-3">
												<div style="color:#9EA0A5">Expiration Date</div>
												<div v-if="addon.allocationExpiresAt">{{
													formatDate(addon.allocationExpiresAt) }}
												</div>
												<div v-else>N/A</div>
											</div>
										<div class="pr-3" style="min-width:150px; width:250px">
											<div class="dF aC jSB" style="gap: 20px">
												<a-button type="primary" ghost @click="onCommissionUpdate(addon, 'parking')" :disabled="!isAddOnCommissionVisible">
													Commission
												</a-button>
												<a-button v-if="!addon.allocatedTo" :disabled="addon.status !== 'available'" type="primary" ghost
													@click="onAssign(addon, 'parking')">
													Assign
												</a-button>
												<a-button v-else :disabled="addon.status !== 'allocated'" type="primary" ghost
													@click="onUnassignAddOn(addon, 'parking')">
													Unassign
												</a-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<EmptyApp :text="'No parking spots found'"
								:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
						</div>
					</template>
				</a-tab-pane>
				<a-tab-pane v-if="instance.productType === 'highrise'" key="lockers" tab="Lockers">
					<template>
						<div class="dF aC" style="justify-content:space-between">
							<div class="dF aC">
								<a-checkbox :indeterminate="indeterminateLockers" :checked="checkAllLockers"
									@change="toggleAllSelection($event, lockersAddOns, 'selectedLockers', 'indeterminateLockers', 'checkAllLockers')" class="mr-3" />
								<h5 style="margin-bottom:0" class="mr-3">Lockers</h5>
								<div class="dF" style="flex-wrap: wrap;">
									<div v-for="(status) in statuses" :key="status.value" :style="status.value != 'total'
											? `background-color:${status.color};`
											: 'border:1px solid #D0C9D6'
										" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
										<a-checkbox @change="changeSelect(status.value)"
											:checked="selectType.includes(status.value)" :style="status.value != 'total' && status.value != 'hold'
													? 'color:#FFF'
													: ''
												">{{ status.select }}</a-checkbox>
									</div>
								</div>
							</div>
							<div class="dF aC">
								<a-dropdown :trigger="['click']" v-if="selectedLockers.length != 0 && $p >= 40">
									<a-button style="margin-left: 8px"> Bulk Actions <a-icon type="down" /> </a-button>
									<a-menu slot="overlay" @click="(e) => onBulkAction(e.key, selectedLockers, 'lockers')">
										<a-menu-item key="assign"> Bulk Assign </a-menu-item>
										<a-menu-item key="unassign"> Bulk Unassign </a-menu-item>
										<a-menu-item key="commission" v-if="isAddOnCommissionVisible"> Bulk Commission </a-menu-item>
									</a-menu>
								</a-dropdown>
								<div @click="openFilter" class="dF aC px-3 py-1 mx-2"
									style="border-radius: 3px; background-color: #fff; cursor: pointer; border: 1px solid #ecebed;">
									<div>Filters</div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-filter ml-2">
										<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
									</svg>
								</div>
							</div>
						</div>
						<div class="dF mt-3" style="gap: 2rem; flex-direction: column;" v-if="lockersAddOns && lockersAddOns.length">
							<div v-for="(addon, addonI) in lockersAddOns" :key="addon.id + addonI">
									<div class="display-card py-3 px-3">
										<div class="dF jSB" style="justify-content:space-between">
											<a-checkbox class="pr-3 pt-1" @change="toggleSelection(addon.id, lockersAddOns, 'selectedLockers', 'indeterminateLockers', 'checkAllLockers')"
												:checked="selectedLockers.includes(addon.id)" />
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Locker ID</div>
												<div>{{ addon.lID }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Locker #</div>
												<div>{{ addon.name }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Status</div>
												<div>{{ salesStatus[addon.status] }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Commission</div>
												<div v-if="isAddOnCommissionVisible">
													<div
														v-if="addon.commissionStructure && addon.commissionStructure.commissionAmount">
														{{ addon.commissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addon.commissionStructure.commissionAmount.toLocaleString() }}
														{{
															addon.commissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div
														v-else-if="addOnGeneralCommissionStructure && addOnGeneralCommissionStructure.commissionAmount">
														{{ addOnGeneralCommissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addOnGeneralCommissionStructure.commissionAmount.toLocaleString() }}
														{{
															addOnGeneralCommissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div v-else> N/A</div>
												</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Assignee</div>
												<div v-if="addon.allocatedTo">{{ getAssigneeName(addon.allocatedTo) }}</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Expiration Date</div>
												<div v-if="addon.allocationExpiresAt">{{
													formatDate(addon.allocationExpiresAt) }}
												</div>
												<div v-else>N/A</div>
											</div>
										<div class="pr-3" style="min-width:150px; width:250px">
											<div class="dF aC jSB" style="gap: 20px">
												<a-button type="primary" ghost @click="onCommissionUpdate(addon, 'lockers')" :disabled="!isAddOnCommissionVisible">
													Commission
												</a-button>
												<a-button v-if="!addon.allocatedTo" :disabled="addon.status !== 'available'" type="primary" ghost
													@click="onAssign(addon, 'lockers')">
													Assign
												</a-button>
												<a-button v-else type="primary" ghost
													@click="onUnassignAddOn(addon, 'lockers')">
													Unassign
												</a-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<EmptyApp :text="'No lockers found'"
								:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
						</div>
					</template>
				</a-tab-pane>
				<a-tab-pane v-if="instance.productType === 'highrise'" key="bikeRacks" tab="Bike Racks">
					<template>
						<div class="dF aC" style="justify-content:space-between">
							<div class="dF aC">
								<a-checkbox :indeterminate="indeterminateBikeRacks" :checked="checkAllBikeRacks"
									@change="toggleAllSelection($event, bikeRacksAddOns, 'selectedBikeRacks', 'indeterminateBikeRacks', 'checkAllBikeRacks')" class="mr-3" />
								<h5 style="margin-bottom:0" class="mr-3">Bike Racks</h5>
								<div class="dF" style="flex-wrap: wrap;">
									<div v-for="(status) in statuses" :key="status.value" :style="status.value != 'total'
											? `background-color:${status.color};`
											: 'border:1px solid #D0C9D6'
										" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
										<a-checkbox @change="changeSelect(status.value)"
											:checked="selectType.includes(status.value)" :style="status.value != 'total' && status.value != 'hold'
													? 'color:#FFF'
													: ''
												">{{ status.select }}</a-checkbox>
									</div>
								</div>
							</div>
							<div class="dF aC">
								<a-dropdown :trigger="['click']" v-if="selectedBikeRacks.length != 0 && $p >= 40">
									<a-button style="margin-left: 8px"> Bulk Actions <a-icon type="down" /> </a-button>
									<a-menu slot="overlay"
										@click="(e) => onBulkAction(e.key, selectedBikeRacks, 'bikeRacks')">
										<a-menu-item key="assign"> Bulk Assign </a-menu-item>
										<a-menu-item key="unassign"> Bulk Unassign </a-menu-item>
										<a-menu-item key="commission" v-if="isAddOnCommissionVisible"> Bulk Commission </a-menu-item>
									</a-menu>
								</a-dropdown>
								<div @click="openFilter" class="dF aC px-3 py-1 mx-2"
									style="border-radius: 3px; background-color: #fff; cursor: pointer; border: 1px solid #ecebed;">
									<div>Filters</div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" class="feather feather-filter ml-2">
										<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
									</svg>
								</div>
							</div>
						</div>
						<div class="dF mt-3" style="gap: 2rem; flex-direction: column;" v-if="bikeRacksAddOns && bikeRacksAddOns.length">
							<div v-for="(addon, addonI) in bikeRacksAddOns" :key="addon.id + addonI">
									<div class="display-card py-3 px-3">
										<div class="dF jSB" style="justify-content:space-between">
											<a-checkbox class="pr-3 pt-1" @change="toggleSelection(addon.id, bikeRacksAddOns, 'selectedBikeRacks', 'indeterminateBikeRacks', 'checkAllBikeRacks')"
												:checked="selectedBikeRacks.includes(addon.id)" />
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Bike Rack ID</div>
												<div>{{ addon.bID }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Bike Rack #</div>
												<div>{{ addon.name }}</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Status</div>
												<div>{{ salesStatus[addon.status] }}</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Commission</div>
												<div v-if="isAddOnCommissionVisible">
													<div
														v-if="addon.commissionStructure && addon.commissionStructure.commissionAmount">
														{{ addon.commissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addon.commissionStructure.commissionAmount.toLocaleString() }}
														{{
															addon.commissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div
														v-else-if="addOnGeneralCommissionStructure && addOnGeneralCommissionStructure.commissionAmount">
														{{ addOnGeneralCommissionStructure.commissionType === 'amount' ? '$'
															: '' }} {{ addOnGeneralCommissionStructure.commissionAmount.toLocaleString() }}
														{{
															addOnGeneralCommissionStructure.commissionType === 'percentage' ? '%' : '' }}
													</div>
													<div v-else> N/A</div>
												</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:100px; width:160px">
												<div style="color:#9EA0A5">Assignee</div>
												<div v-if="addon.allocatedTo">{{ getAssigneeName(addon.allocatedTo) }}</div>
												<div v-else> N/A</div>
											</div>
											<div class="pr-3" style="min-width:120px; width:180px">
												<div style="color:#9EA0A5">Expiration Date</div>
												<div v-if="addon.allocationExpiresAt">{{
													formatDate(addon.allocationExpiresAt) }}
												</div>
												<div v-else>N/A</div>
											</div>
										<div class="pr-3" style="min-width:150px; width:250px">
											<div class="dF aC jSB" style="gap: 20px">
												<a-button type="primary" ghost @click="onCommissionUpdate(addon, 'bikeRacks')" :disabled="!isAddOnCommissionVisible">
													Commission
												</a-button>
												<a-button v-if="!addon.allocatedTo" :disabled="addon.status !== 'available'" type="primary" ghost
													@click="onAssign(addon, 'bikeRacks')">
													Assign
												</a-button>
												<a-button v-else type="primary" ghost
													@click="onUnassignAddOn(addon, 'bikeRacks')">
													Unassign
												</a-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<EmptyApp :text="'No bike racks found'"
								:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
						</div>
					</template>
				</a-tab-pane>
			</a-tabs>
		</div>
		<a-modal v-model="commissionModalVisible" title="Commission Structure">
			<a-form-model ref="commissionStructure" :model="commissionStructure" class="profile">
				<a-row type="flex" :gutter="20" v-if="selectedLot">
					<a-col :span="12">
						<a-form-model-item label="Selected Lot #">
							<a-input :value="selectedLot.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20" v-if="selectedUnit">
					<a-col :span="12">
						<a-form-model-item label="Selected Unit Number">
							<a-input :value="selectedUnit.unitNumber" disabled size="large" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Selected Unit Marketing Name">
							<a-input :value="selectedUnit.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20" v-else-if="selectedAddOn">
					<a-col :span="12">
						<a-form-model-item :label="`Selected ${addOn[selectedTab]} ID`">
							<a-input :value="selectedAddOn.pID || selectedAddOn.lID || selectedAddOn.bID" disabled size="large" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item :label="`Selected ${addOn[selectedTab]} Name`">
							<a-input :value="selectedAddOn.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20" v-else-if="selectedLots.length || selectedUnits.length || selectedParking.length || selectedLockers.length || selectedBikeRacks.length">
					<a-col :span="24" class="mb-3">
						<h5> {{ 'Total Selected ' + commissionStructure.title }} : {{ commissionStructure.selectedData.length }} </h5>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20">
					<a-col :span="12">
						<a-form-model-item label="Commission Fee Type" prop="commissionType">
							<a-radio-group v-model="commissionStructure.commissionType" size="large" @change="commissionStructure.commissionAmount = null">
								<a-radio-button value="percentage">
									Percentage
								</a-radio-button>
								<a-radio-button value="amount">
									Amount
								</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Commission Fee" prop="commissionAmount"
							:rules="req('Please enter commission amount')">
							<a-input-number v-model="commissionStructure.commissionAmount" :formatter="(price) =>
									`${commissionStructure.commissionType === 'amount' ? '$' : ''} ${price}${commissionStructure.commissionType === 'percentage' ? ' %' : ''}`.replace(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								" :min="0" style="width: 100%" size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<template slot="footer">
				<a-button key="back" @click="commissionModalVisible = false">
					cancel
				</a-button>
				<a-button v-if="selectedCommissionStructure && selectedCommissionStructure.commissionAmount" key="reset" type="primary" ghost @click="resetCommissionStructure" :loading="loading">
					Reset To General Commission Structure
				</a-button>
				<a-button key="submit" type="primary" :loading="loading" @click="onCommissionStructureUpdate">
					UPDATE
				</a-button>
			</template>
		</a-modal>

		<a-modal v-model="modalVisible" :title="`Assign ${allocation.title} to`" @ok="onSubmit" okText="ASSIGN">
			<a-form-model ref="allocation" :model="allocation" class="profile">
				<a-row type="flex" :gutter="20" v-if="selectedLot">
					<a-col :span="24">
						<a-form-model-item label="Selected Lot #">
							<a-input :value="selectedLot.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20" v-else-if="selectedUnit">
					<a-col :span="24">
						<a-form-model-item label="Selected Unit">
							<a-input :value="selectedUnit.unitNumber + ' ' + selectedUnit.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20" v-else-if="selectedAddOn">
					<a-col :span="24">
						<a-form-model-item :label="`Selected ${allocation.title}`">
							<a-input :value="selectedAddOn.name" disabled size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20"
					v-else-if="selectedLots.length || selectedUnits.length || selectedParking.length || selectedLockers.length || selectedBikeRacks.length">
					<a-col :span="24" class="mb-3">
						<h5>
							{{ 'Total Selected ' + allocation.title }} : {{ allocation.selectedData.length }} </h5>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20">
					<a-col :span="24">
						<a-form-model-item label="VIP Realtor" required :rules="req('Please select realtor')" prop="agent">
							<a-select v-model="allocation.agent" size="large">
								<a-select-option v-for="realtor in realtors" :key="realtor.id" :value="realtor.id">{{
									realtor.firstName + " " + realtor.lastName }} ({{ realtor.email }})</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="20">
					<a-col :span="24">
						<a-form-model-item required :rules="req('Please select the expiry date of allocation')"
							prop="allocationExpiresAt">
							<template slot="label">
								Expiration Date
								<a-tooltip overlayClassName="change-tooltip-color">
									<template slot="title">
										Use this field to set a time limit for your agent. Note: Once date is
										reached {{ allocation.type }} will revert to available status.
									</template>
									<a-icon type="question-circle"
										style="font-size: 14px; color: black; margin-left: 2px;" />
								</a-tooltip>
							</template>
							<a-date-picker v-model="allocation.allocationExpiresAt" :disabled-date="disabledDate"
								style="width: 100%" size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from 'bh-mod'
import moment from "moment"
import FilterDrawer from "@/components/inventory/FilterDrawer";
import EmptyApp from 'bh-mod/components/common/EmptyApp'

export default {
	components: { FilterDrawer, EmptyApp },
	data() {
		return {
			selectedTab: 'units',
			commissionModalVisible: false,
			modalVisible: false,
			selectedLot: null,
			selectedUnit: null,
			selectedAddOn: null,
			allocation: {
				agent: null,
				allocationExpiresAt: null,
				type: 'unit',
				title: 'Units',
				selectedData: []
			},
			commissionStructure: {
				commissionType: 'percentage',
				commissionAmount: null,
				type: 'unit',
				title: 'Units',
				selectedData: []
			},
			selectedLots: [],
			selectedUnits: [],
			selectedParking: [],
			selectedLockers: [],
			selectedBikeRacks: [],
			checkAllLots: false,
			checkAllUnits: false,
			checkAllParking: false,
			checkAllLockers: false,
			checkAllBikeRacks: false,
			indeterminateLots: false,
			indeterminateUnits: false,
			indeterminateParking: false,
			indeterminateLockers: false,
			indeterminateBikeRacks: false,
			selectType: ["total"],
			salesStatus: {
				available:'Available',
				inventory:'Inventory',
				sold:'Sold',
				conditional:'Conditional',
				soldConditional:'Sold conditional',
				hold:'Hold',
				notreleased:'Not released',
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			showScrollArrows: false,
			resizeObserver: null,
			addOns: {
				parking: 'Parking Spots',
				lockers: 'Lockers',
				bikeRacks: 'Bike Racks',
			},
			addOn: {
				parking: 'Parking Spot',
				lockers: 'Locker',
				bikeRacks: 'Bike Rack',
			}
		}
	},

	computed: {
		loading: {
			get(){
				return this.$store.state.loading
			},
			set(val){
				this.$store.commit('LOAD', val)
			}
		},
		instance() {
			return this.$store.state.instance
		},

		realtors() {
			return this.$store.state.realtors
		},

		statuses() {
			const list = [
				{
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				},
				{
					value: "allocated",
					color: "var(--primary)",
					select: "Allocated",
				},
				{
					value: "approved_for_reservation",
					color: "#134F5C",
					select: "Approved",
				},
				{
					value: "reserved",
					color: "#419E00",
					select: "Reserved",
				},
			]

			if (this.instance.productType === "lowrise") {
				list.splice(5, 0, { value: "inventory", color: "#3395c6", select: "Standing Inventory" })
			}

			return list
		},

		unitsByBedType() {
			return this.$store.getters.highRiseOptions.map;
		},
		unitsByPrice() {
			return this.$store.getters.unitsByPrice;
		},
		unitsBySqft() {
			return this.$store.getters.unitsBySqft;
		},

		storeLots() {
            return this.$store.state.inventory.lots;
        },

		lots() {
			let state = this.$store.state.inventory;
			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					if (parseInt(a.name) < parseInt(b.name)) return -1;
					if (parseInt(a.name) > parseInt(b.name)) return 1;
					else return 0;
				});
				return array;
			};

			// Keep this value as it's being user for updating new value into DOM
			let time = this.$store.state.inventory.newTime;

			let data = state.lowriseFilter;

			let statuses = this.selectType;

			if (
				statuses && statuses[0] == "total" &&
				data.modelType && data.modelType[0] == "any" &&
				data.street == "any" &&
				data.tags && data.tags[0] === "any" &&
				data.frontage && data.frontage[0] == 0 &&
				data.unitGroup == "any" &&
				data.elevation == "any" &&
				data.premiums && data.premiums[0] == "any" &&
				data.price && data.price[1] == 0
			) {
				return orderAlpha(Object.values(this.$store.state.inventory.lots));
			}

			const checkPrice = (lot, prices) => {
				if (prices[1] == 0) return true;

				let list = [];

				Object.keys(this.lotsByPremiums.priceMap).forEach((price) => {
					if (price >= prices[0] && price <= prices[1]) {
						list = list.concat(this.lotsByPremiums.priceMap[price]);
					}
				});

				if (list.includes(lot.id)) return true;
				else return false;
			};

			const checkPremiums = (lot, premiums) => {
				if (premiums[0] == "any") return true;
				if (premiums[0] == "none") {
					if (lot.other && lot.other.premiums) {
						let list = [];
						Object.keys(lot.other.premiums).forEach((key) => {
							if (lot.other.premiums[key].active && this.allPremiums.includes(lot.other.premiums[key].id)) list.push(key);
						});
						if (list.length == 0) return true;
						else return false;
					}
					else return true;
				}

				let list = [];
				if (lot.other && lot.other.premiums) {
					Object.keys(lot.other.premiums).forEach((key) => {
						if (lot.other.premiums[key].active) list.push(key);
					});
				}

				let pass = false;
				list.forEach((prem) => {
					if (premiums.includes(prem)) pass = true;
				});

				return pass;
			};

			const checkFrontage = (lot, frontages) => {
				if (frontages[0] == 0) return true;

				let pass = true;
				lot.units.forEach((unit) => {
					let id = unit;
					if (unit.id) id = unit.id;
					if (
						!state.units[id] ||
						!state.units[id].unitGroup ||
						!frontages.includes(state.units[id].unitGroup.size)
					)
						pass = false;
				});
				return pass;
			};

			const checkUnitGroup = (lot, unitGroup) => {
				if (unitGroup == "any") return true;

				let pass = false;
				lot.units.forEach((unit) => {
					let id = unit;
					if (unit.id) id = unit.id;
					if (
						state.units[id] &&
						state.units[id].unitGroup &&
						unitGroup == state.units[id].unitGroup.id
					)
						pass = true;
				});
				return pass;
			};

			const checkElevation = (lot, elevation) => {
				if (elevation == "any") return true;

				let pass = false;
				if (!(lot.units && lot.units.length)) return false;
				lot.units.forEach((unit) => {
					let id = unit;
					if (unit.id) id = unit.id;
					if (
						state.units[id] &&
						elevation == state.units[id].name
					)
						pass = true;
				});
				return pass;
			};

			const checkModelType = (lot, types) => {
				if (types[0] === "any") return true;

				let pass = true;
				lot.units.forEach((unit) => {
					let id = unit;
					if (unit.id) id = unit.id;
					if (
						!state.units[id] ||
						!state.units[id].unitGroup ||
						!types.includes(state.units[id].unitGroup.type)
					)
						pass = false;
				});
				return pass;
			};

			const checkTags = (lot, tags) => {
				if (tags[0] === "any") return true;

				const isFound = (arr1, arr2) => {
					let found = false;
					arr1.forEach((str) => {
						if (arr2.includes(str)) found = true;
					});
					return found;
				};

				let pass = true;
				if (!isFound(lot.tags, tags)) pass = false;
				return pass;
			};

			const checkStatus = (lot, status) => {
				if (lot.publishStatus == null) lot.publishStatus = "draft";
				if (status[0] === "total") return true;
				if (lot.status == 'conditional' || lot.status == 'soldConditional') {
					if (status.includes('conditional')) return true;
				} else {
					return (
						status.includes(lot.status) ||
						status.includes(lot.publishStatus)
					);
				}
			};
			const checkStreet = (lot, street) => {
				if (street == "any") return true;
				return lot.street == street;
			};

			return orderAlpha(
				Object.values(this.$store.state.inventory.lots).filter((lot) => {
					if (!checkModelType(lot, data.modelType)) return false;
					if (!checkTags(lot, data.tags)) return false;
					if (!checkStatus(lot, statuses)) return false;
					if (!checkStreet(lot, data.street)) return false;
					if (!checkFrontage(lot, data.frontage)) return false;
					if (!checkUnitGroup(lot, data.unitGroup)) return false;
					if (!checkElevation(lot, data.elevation)) return false;
					if (!checkPremiums(lot, data.premiums)) return false;
					if (!checkPrice(lot, data.price)) return false;
					return true;
				})
			);
		},

		lotUnits() {
            let units = {};
            Object.values(this.$store.state.inventory.units).forEach((unit) => {
                if (unit.buildingType == "models") {
                    units[unit.id] = unit;
                }
            });
            return units;
        },

		units() {
			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					let aa = parseInt(a.unitNumber) || a.unitNumber;
					let bb = parseInt(b.unitNumber) || b.unitNumber;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
				});
				return array;
			};
			let units = {};
			let data = this.$store.state.inventory.highriseFilter;
			let statuses = this.selectType;
			Object.values(this.$store.state.inventory.units).forEach((unit) => {
				if (unit.buildingType == "condounits") {
					units[unit.id] = unit;
				}
			});
			if (
				statuses[0] == "total" &&
				data.type[0] == "any" &&
				data.price[1] == 0 &&
				data.sqft[1] == 0 &&
				data.beds[0] == "any" &&
				data.baths == "any" &&
				data.floor == "any" &&
				data.exposure[0] == "any" &&
				data.assignee[0] == "any" &&
				data.exterior[0] == "any" &&
				data.tags[0] == "any"
			) {
				return orderAlpha(Object.values(units));
			}

			const checkPrice = (unit, prices) => {
				if (prices[1] == 0) return true;

				let list = [];

				Object.keys(this.unitsByPrice.priceMap).forEach((price) => {
					if (price >= prices[0] && price <= prices[1]) {
						list = list.concat(this.unitsByPrice.priceMap[price]);
					}
				});

				if (list.includes(unit.id)) return true;
				else return false;
			};

			const checkSqft = (unit, sqft) => {
				if (sqft[1] == 0) return true;

				let list = [];

				Object.keys(this.unitsBySqft.sqftMap).forEach((sqFt) => {
					if (sqFt >= sqft[0] && sqFt <= sqft[1]) {
						list = list.concat(this.unitsBySqft.sqftMap[sqFt]);
					}
				});

				if (list.includes(unit.id)) return true;
				else return false;
			};

			const checkStatus = (unit, status) => {
				if (unit.status == null) unit.status = "draft";
				if (status[0] === "total") return true;
				if (status.includes("available")) {
					return (
						status.includes(unit.salesStatus) ||
						unit.salesStatus == "inventory"
					);
				}
				return (
					status.includes(unit.salesStatus) ||
					status.includes(unit.status)
				);
			};

			const checkBedType = (unit, beds) => {
				if (beds[0] == "any") return true;

				let list = [];

				beds.forEach((bedType) => {
					if (this.unitsByBedType[bedType]) {
						list = list.concat(this.unitsByBedType[bedType]);
					}
				});

				if (list.includes(unit.id)) return true;
				else return false;
			};

			const checkBath = (unit, baths) => {
				if (baths == "any") return true;
				let pass = true;

				if (
					unit.packages &&
					unit.packages.length &&
					unit.packages[0].baths &&
					unit.packages[0].baths == baths
				)
					return pass;
				else return false;
			};

			const checkExposure = (unit, exposure) => {
				if (exposure[0] == "any") return true;

				let pass = true;
				if (
					unit.packages &&
					unit.packages.length &&
					unit.packages[0].exposure &&
					exposure.includes(unit.packages[0].exposure)
				)
					return pass;
				else return false;
			};

			const checkAssignee = (unit, assignee) => {
				if (assignee[0] == "any") return true;

				let pass = true;
				if (assignee.includes("none") && (!unit.allocatedTo || !unit.allocatedTo.id)){
					return pass;
				} else if (
					unit.allocatedTo &&
					unit.allocatedTo.id &&
					assignee.includes(unit.allocatedTo.id)
				)
					return pass;
				else return false;
			};

			const checkExterior = (unit, exterior) => {
				if (exterior[0] == "any") return true;

				let pass = true;

				if (
					exterior.includes("penthouse") &&
					unit.packages &&
					unit.packages.length &&
					unit.packages[0].hasPenthouse
				)
					return pass;
				if (
					exterior.includes("den") &&
					unit.packages &&
					unit.packages.length &&
					unit.packages[0].hasDen
				)
					return pass;
				if (
					exterior.includes("terrace") &&
					unit.packages &&
					unit.packages.length &&
					unit.packages[0].hasTerrace
				)
					return pass;

				return false;
			};

			const checkFloor = (unit, floor) => {
				if (floor == "any") return true;

				let pass = true;

				let id = "";

				if (unit.unitGroup) id = unit.unitGroup;
				if (unit.unitGroup.id) id = unit.unitGroup.id;

				if (
					floor == id &&
					unit.packages &&
					unit.packages.length &&
					!unit.packages[0].hasCondo
				)
					return pass;
				return false;
			};

			const checkTags = (unit, tags) => {
				if (tags[0] === "any") return true;

				const isFound = (arr1, arr2) => {
					let found = false;
					arr1.forEach((str) => {
						if (arr2.includes(str)) found = true;
					});
					return found;
				};

				let pass = true;
				if (!isFound(unit.tags, tags)) pass = false;
				return pass;
			};


			return orderAlpha(
				Object.values(this.$store.state.inventory.units).filter(
					(unit) => {
						if(unit.buildingType !== "condounits") return false;
						if (!checkPrice(unit, data.price)) return false;
						if (!checkSqft(unit, data.sqft)) return false;
						if (!checkStatus(unit, statuses)) return false;
						if (!checkBedType(unit, data.beds)) return false;
						if (!checkBath(unit, data.baths)) return false;
						if (!checkExposure(unit, data.exposure)) return false;
						if (!checkAssignee(unit, data.assignee)) return false;
						if (!checkExterior(unit, data.exterior)) return false;
						if (!checkFloor(unit, data.floor)) return false;
						if (!checkTags(unit, data.tags)) return false;
						return true;
					}
				)
			);
		},

		condoAddons() {
			let condoAddons = JSON.parse(
				JSON.stringify(this.$store.state.inventory.condoAddons)
			);

			let data = this.$store.state.inventory.addOnsFilter;
			let statuses = this.selectType;

			if (
				statuses[0] == "total" &&
				data.parkingType[0] == "any" &&
				data.lockersType[0] == "any" &&
				data.parkingPrice[1] == 0 &&
				data.lockersPrice[1] == 0 &&
				data.bikeRacksPrice[1] == 0 &&
				data.assignee[0] == "any" &&
				data.tags[0] == "any"
			) {
				return condoAddons;
			}

			const checkStatus = (addon, status) => {
				if (addon.status == null) addon.status = "draft";
				if (status[0] === "total") return true;
				if (status.includes("available")) {
					return status.includes(addon.status);
				}
				return status.includes(addon.status);
			};

			const checkPrice = (addOn, prices) => {
				if (prices[1] == 0) return true;

				if (addOn.price >= prices[0] && addOn.price <= prices[1]) {
					return true;
				} else return false;
			};

			const checkAssignee = (addOn, assignee) => {
				if (assignee[0] == "any") return true;

				let pass = true;
				if (assignee.includes("none") && !addOn.allocatedTo) {
					return pass;
				}
				else if (
					addOn.allocatedTo &&
					assignee.includes(addOn.allocatedTo)
				)
					return pass;
				else return false;
			};

			const checkType = (addOn, type) => {
				if (type[0] == "any") return true;

				let pass = true;
				if (
					addOn &&
					addOn.type &&
					type.includes(addOn.type)
				)
					return pass;
				else return false;
			};

			condoAddons.parking = condoAddons.parking.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.parkingPrice)) return false;
				if (!checkType(addon, data.parkingType)) return false;
				return true;
			});
			condoAddons.lockers = condoAddons.lockers.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.lockersPrice)) return false;
				if (!checkType(addon, data.lockersType)) return false;
				return true;
			});
			condoAddons.bikeRacks = condoAddons.bikeRacks.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.bikeRacksPrice)) return false;
				return true;
			});

			return condoAddons;
		},

		parkingAddOns() {
			const parking = this.condoAddons && this.condoAddons.parking || [];

			return parking.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},

		lockersAddOns() {
			const lockers = this.condoAddons && this.condoAddons.lockers || [];

			return lockers.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},

		bikeRacksAddOns() {
			const bikeRacks = this.condoAddons && this.condoAddons.bikeRacks || [];

			return bikeRacks.sort((a, b) => {
				let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
				let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
				return aa - bb;
			})
		},

		appSettings() {
			let app = this.$store.state.inventory.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		agentCommissionStructure() {
			return this.appSettings.agentCommissionStructure || {
				commissionType: "percentage",
				commissionAmount: null,
				lotCommissionType: "percentage",
				lotCommissionAmount: null,
				lotCommissionIsActive: false,
				unitCommissionType: "percentage",
				unitCommissionAmount: null,
				unitCommissionIsActive: true,
				addOnCommissionType: "percentage",
				addOnCommissionAmount: null,
				addOnCommissionIsActive: true
			};
		},

		agentCommissionType() {
			return this.appSettings.agentCommissionType !== 'individual' || this.instance.productType === 'lowrise' ? 'units_and_addons' : 'individual';
		},

		isLotCommissionVisible() {
			return this.instance.productType === 'lowrise' && this.agentCommissionStructure.lotCommissionIsActive;
		},

		isUnitCommissionVisible() {
			return this.agentCommissionType === 'units_and_addons' || this.agentCommissionStructure.unitCommissionIsActive
		},

		isAddOnCommissionVisible() {
			return this.agentCommissionType === 'units_and_addons' || this.agentCommissionStructure.addOnCommissionIsActive
		},

		lotGeneralCommissionStructure() {
			return {
				commissionType: this.agentCommissionStructure.lotCommissionType,
				commissionAmount: this.agentCommissionStructure.lotCommissionAmount
			}
		},

		unitGeneralCommissionStructure() {
			if(this.agentCommissionType === 'units_and_addons') {
				return  {
					commissionType: this.agentCommissionStructure.commissionType,
					commissionAmount: this.agentCommissionStructure.commissionAmount
				}
			} else {
				return  {
					commissionType: this.agentCommissionStructure.unitCommissionType,
					commissionAmount: this.agentCommissionStructure.unitCommissionAmount
				}
			}
		},

		addOnGeneralCommissionStructure() {
			if(this.agentCommissionType === 'units_and_addons') {
				return  {
					commissionType: this.agentCommissionStructure.commissionType,
					commissionAmount: this.agentCommissionStructure.commissionAmount
				}
			} else {
				return  {
					commissionType: this.agentCommissionStructure.addOnCommissionType,
					commissionAmount: this.agentCommissionStructure.addOnCommissionAmount
				}
			}
		},

		selectedCommissionStructure() {
			if (this.selectedTab === 'lots') {
				return this.lotGeneralCommissionStructure
			} else if (this.selectedTab === 'units') {
				return this.unitGeneralCommissionStructure
			} else {
				return this.addOnGeneralCommissionStructure
			}
		}
	},

	watch: {
		commissionModalVisible: {
			handler(val) {
				if (val) {
					if (this.selectedTab === 'lots') {
						const commissionStructure = this.selectedLot && this.selectedLot.commissionStructure;
						if (commissionStructure && commissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = commissionStructure.commissionType;
							this.commissionStructure.commissionAmount = commissionStructure.commissionAmount;
						} else if (this.lotGeneralCommissionStructure && this.lotGeneralCommissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = this.lotGeneralCommissionStructure.commissionType;
							this.commissionStructure.commissionAmount = this.lotGeneralCommissionStructure.commissionAmount;
						}
					} else if (this.selectedTab === 'units') {
						const commissionStructure = this.selectedUnit && this.selectedUnit.commissionStructure;
						if (commissionStructure && commissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = commissionStructure.commissionType;
							this.commissionStructure.commissionAmount = commissionStructure.commissionAmount;
						} else if (this.unitGeneralCommissionStructure && this.unitGeneralCommissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = this.unitGeneralCommissionStructure.commissionType;
							this.commissionStructure.commissionAmount = this.unitGeneralCommissionStructure.commissionAmount;
						}
					} else if (['parking', 'lockers', 'bikeRacks'].includes(this.selectedTab)) {
						const commissionStructure = this.selectedAddOn && this.selectedAddOn.commissionStructure;
						if (commissionStructure && commissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = commissionStructure.commissionType;
							this.commissionStructure.commissionAmount = commissionStructure.commissionAmount;
						} else if (this.addOnGeneralCommissionStructure && this.addOnGeneralCommissionStructure.commissionAmount) {
							this.commissionStructure.commissionType = this.addOnGeneralCommissionStructure.commissionType;
							this.commissionStructure.commissionAmount = this.addOnGeneralCommissionStructure.commissionAmount;
						}
					}
				} else {
					this.commissionStructure.commissionType = 'percentage';
					this.commissionStructure.commissionAmount = null;
					this.selectedLot = null;
					this.selectedUnit = null;
					this.selectedAddOn = null;
				}
			}
		}
	},

	created() {
		this.$store.commit("UPDATE_CRUMBS", "allocation");
		this.$store.dispatch('FETCH_REALTORS')
	},

	methods: {
		formatDate,
		req: (msg) => ({ required: true, message: msg }),

		onAssign(data, type) {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to allocate ${type}`)
			}

			if (
				(type === 'unit' && data.salesStatus && data.salesStatus !== 'available') ||
				(['lot', 'parking', 'lockers', 'bikeRacks'].includes(type) && data.status && data.status !== 'available')
			) {
				return;
			}

			this.selectedLot = null;
			this.selectedUnit = null;
			this.selectedAddOn = null;
			this.allocation.type = type

			if (type === 'lot') {
				this.selectedLot = data;
				this.allocation.title = 'Lot'
			} else if (type === 'unit') {
				this.selectedUnit = data;
				this.allocation.title = 'Unit'
			} else if (type === 'parking') {
				this.selectedAddOn = data;
				this.allocation.title = 'Parking Spot'
			} else if (type === 'lockers') {
				this.selectedAddOn = data;
				this.allocation.title = 'Locker'
			} else if (type === 'bikeRacks') {
				this.selectedAddOn = data;
				this.allocation.title = 'Bike Rack'
			}

            this.allocation.agent = null;
            this.allocation.allocationExpiresAt = null;
			this.modalVisible = true;
		},

		onCommissionUpdate(data, type) {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to update commission of ${type}`)
			} else if (type === 'lot' && !this.isLotCommissionVisible) {
				return this.$message.error('Lot Commission Structure is not setup or inactive.');
			} else if (type === 'unit' && !this.isUnitCommissionVisible) {
				return this.$message.error('Unit Commission Structure is not setup or inactive.');
			} else if (['parking', 'lockers', 'bikeRacks'].includes(type) && !this.isAddOnCommissionVisible) {
				return this.$message.error('Add On Commission Structure is not setup or inactive.');
			}

			this.selectedLot = null;
			this.selectedUnit = null;
			this.selectedAddOn = null;
			this.commissionStructure.type = type;

			if (type === 'lot') {
				this.selectedLot = data;
				this.commissionStructure.title = 'Lot'
			} else if (type === 'unit') {
				this.selectedUnit = data;
				this.commissionStructure.title = 'Unit'
			} else if (type === 'parking') {
				this.selectedAddOn = data;
				this.commissionStructure.title = 'Parking Spot'
			} else if (type === 'lockers') {
				this.selectedAddOn = data;
				this.commissionStructure.title = 'Locker'
			} else if (type === 'bikeRacks') {
				this.selectedAddOn = data;
				this.commissionStructure.title = 'Bike Rack'
			}

			this.commissionModalVisible = true;
		},

		onBulkAction(action, ids, type = 'unit') {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to allocate ${type}`)
			} else if (type === 'lot' && !this.isLotCommissionVisible) {
				return this.$message.error('Lot Commission Structure is not setup or inactive.');
			} else if (type === 'unit' && !this.isUnitCommissionVisible) {
				return this.$message.error('Unit Commission Structure is not setup or inactive.');
			} else if (['parking', 'lockers', 'bikeRacks'].includes(type) && !this.isAddOnCommissionVisible) {
				return this.$message.error('Add On Commission Structure is not setup or inactive.');
			}

			this.selectedLot = null;
			this.selectedUnit = null;
			this.selectedAddOn = null;
			this.allocation.type = type;

			if (action === 'assign') {
				this.allocation.selectedData = ids
				if (type === 'lot') {
					this.allocation.title = 'Lots'
				} else if (type === 'unit') {
					this.allocation.title = 'Units'
				} else if (type === 'parking') {
					this.allocation.title = 'Parking Spot'
				} else if (type === 'lockers') {
					this.allocation.title = 'Locker'
				} else if (type === 'bikeRacks') {
					this.allocation.title = 'Bike Rack'
				}
				this.modalVisible = true;
			} else if (action === 'commission') {
				this.commissionStructure.selectedData = ids
				if (type === 'lot') {
					this.commissionStructure.title = 'Lots'
				} else if (type === 'unit') {
					this.commissionStructure.title = 'Units'
				} else if (type === 'parking') {
					this.commissionStructure.title = 'Parking Spot'
				} else if (type === 'lockers') {
					this.commissionStructure.title = 'Locker'
				} else if (type === 'bikeRacks') {
					this.commissionStructure.title = 'Bike Rack'
				}
				this.commissionModalVisible = true;
			} else if (action === 'unassign') {
				this.onBulkUnAssign(ids, type)
			}
		},

		onBulkUnAssign(ids, type) {
			const title = type === 'lot' ? 'Lots' : type === 'unit' ? 'Units' : `${this.addOns[type]} Add Ons`
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign ${title}`)
			}
			let self = this
			this.$confirm({
				title: `Unassign ${title}`,
				content: h => <div>Are you sure you want to unassign selected {ids.length} {title}?</div>,
				okText: 'PROCEED',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					let url = `/units/:instance/bulk-un-allocate`;
					let payload = {}
					if (type === 'unit') {
						let unitIds = self.units.filter(u => ids.includes(u.id) && u.salesStatus === 'allocated' && u.allocatedTo).map(u => u.id)
						payload = {
							units: unitIds
						}
						if (payload.units && payload.units.length === 0) {
							return self.$message.error(`No valid units found for unassign. Please select allocated units`);
						}
					} else if (type === 'lot') {
						let lotIds = self.lots.filter(l => ids.includes(l.id) && l.status === 'allocated' && l.allocatedTo).map(l => l.id)
						url = `/lots/:instance/bulk-un-allocate`
						payload = {
							lots: lotIds
						}
						if (payload.lots && payload.lots.length === 0) {
							return self.$message.error(`No valid lots found for allocation. Please select allocated lots`);
						}
					} else if (['parking', 'lockers', 'bikeRacks'].includes(type)) {
						let addOnIds = self.condoAddons[type].filter(a => ids.includes(a.id) && a.status === 'allocated' && a.allocatedTo).map(a => a.id)
						url = `/units/:instance/bulk-un-allocate-addons/${type}`
						payload = {
							ids: addOnIds
						}
						if (payload.ids && payload.ids.length === 0) {
							return self.$message.error(`No valid ${type} found for allocation. Please select allocated ${type}`);
						}
					}

					self.loading = true;
					self.$api.post(url, payload).then(() => {
						self.loading = false;
						self.$message.success(`${title} unassigned successfully!`)
						if (['lot', 'unit'].includes(type)) {
							self.$store.dispatch('FETCH_UPDATED_DATA')
						} else {
							self.$store.dispatch('FETCH_ADD_ONS')
						}
						self.resetAllData()
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error occurred while unAssigning ${title}. Please try again!`))
						}
					})
				},
			});
		},

		onSubmit() {
			let self = this;
			this.$refs.allocation.validate((valid) => {
				if (valid) {
					if (self.allocation.type === 'lot') {
						self.onLotAllocation()
					} else if (self.allocation.type === 'unit') {
						self.onUnitAllocation()
					} else {
						self.onAddOnAllocation()
					}
				} else {
					return
				}
			})
		},

		onLotAllocation() {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign lot`)
			}

			let url = `/lots/:instance/bulk-allocate`;
			let payload = {
				agent: this.allocation.agent,
				expiresAt: +(moment(this.allocation.allocationExpiresAt).format('x')),
			}
			if (this.selectedLot) {
				if (this.selectedLot.status !== 'available') return;
				url = `/lots/:instance/allocate/${this.selectedLot.id}`
			} else {
				payload.lots = this.lots.filter(l => this.selectedLots.includes(l.id) && l.status === 'available').map(l => l.id);
				if (payload.lots && payload.lots.length === 0) {
					return this.$message.error(`No valid lots found for allocation. Please select available lots`);
				}
			}

			this.loading = true;
			this.$api.post(url, {
				...payload
			}).then(({ data }) => {
				this.loading = false;
				this.$message.success(`${this.selectedLot ? 'Lot' : 'Lots'} Assigned Successfully!`)
				if (this.selectedLot) {
					this.$store.commit("SET_PROP", {
						where: ["lots", this.selectedLot.id],
						what: {
							...this.selectedLot,
							allocatedTo: { ...data.allocatedTo },
							allocationExpiresAt: data.allocationExpiresAt,
							status: data.status,
						},
					});
					this.$store.commit("UPDATE_LOTS", {
						...this.selectedLot,
						allocatedTo: { ...data.allocatedTo },
						allocationExpiresAt: data.allocationExpiresAt,
						status: data.status,
					});
				} else {
					this.$store.dispatch('FETCH_UPDATED_DATA')
				}
				this.resetAllData()
				this.modalVisible = false;
			}).catch((err) => {
				this.loading = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `Error occurred while assigning ${this.selectedLot ? 'Lot' : 'Lots'}. Please try again!`))
				}
			})
		},

		onUnitAllocation() {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign lot`)
			}

			let url = `/units/:instance/bulk-allocate`;
			let payload = {
				agent: this.allocation.agent,
				expiresAt: +(moment(this.allocation.allocationExpiresAt).format('x')),
			}
			if (this.selectedUnit) {
				if (this.selectedUnit.salesStatus !== 'available') return;
				url = `/units/:instance/allocate/${this.selectedUnit.id}`
			} else {
				payload.units = this.units.filter(u => this.selectedUnits.includes(u.id) && u.salesStatus === 'available').map(u => u.id);
				if (payload.units && payload.units.length === 0) {
					return this.$message.error(`No valid units found for allocation. Please select available units`);
				}
			}

			this.loading = true;
			this.$api.post(url, {
				...payload
			}).then(({ data }) => {
				this.loading = false;
				this.$message.success(`${this.selectedUnit ? 'Unit' : 'Units'} Assigned Successfully!`)
				if (this.selectedUnit) {
					this.$store.commit("SET_PROP", {
						where: ["units", this.selectedUnit.id],
						what: {
							...this.selectedUnit,
							allocatedTo: { ...data.allocatedTo },
							allocationExpiresAt: data.allocationExpiresAt,
							salesStatus: data.salesStatus,
						},
					});
					this.$store.commit("UPDATE_UNITS", {
						...this.selectedUnit,
						allocatedTo: { ...data.allocatedTo },
						allocationExpiresAt: data.allocationExpiresAt,
						salesStatus: data.salesStatus,
					});
				} else {
					this.$store.dispatch('FETCH_UPDATED_DATA')
				}
				this.resetAllData()
				this.modalVisible = false;
			}).catch((err) => {
				this.loading = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `Error occurred while assigning ${this.selectedUnit ? 'unit' : 'units'}. Please try again!`))
				}
			})
		},

		onAddOnAllocation() {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign lot`)
			}

			let url = `/units/:instance/bulk-allocate-addons/${this.allocation.type}`;
			let payload = {
				agent: this.allocation.agent,
				expiresAt: +(moment(this.allocation.allocationExpiresAt).format('x')),
			}
			if (this.selectedAddOn) {
				if (this.selectedAddOn.status !== 'available') return;
				url = `/units/:instance/allocate-addons/${this.allocation.type}/${this.selectedAddOn.id}`
			} else {
				payload.ids = this.condoAddons[this.allocation.type].filter(a => this.allocation.selectedData.includes(a.id) && a.status === 'available').map(a => a.id);
				if (payload.ids && payload.ids.length === 0) {
					return this.$message.error(`No valid ${this.allocation.type} found for allocation. Please select available ${this.allocation.type}`);
				}
			}

			this.loading = true;
			this.$api.post(url, {
				...payload
			}).then(() => {
				this.loading = false;
				this.$message.success(`${this.allocation.type} Assigned Successfully!`)
				this.$store.dispatch('FETCH_ADD_ONS')
				this.resetAllData()
				this.modalVisible = false;
			}).catch((err) => {
				this.loading = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `Error occurred while assigning ${this.allocation.type}. Please try again!`))
				}
			})
		},

		onUnassignLot(lot) {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign lot`)
			}
			if (lot.status !== 'allocated' && !lot.allocatedTo) return;

			let self = this
			this.$confirm({
				title: 'Unassign Lot',
				content: h => <div>Are you sure you want to unassign lot {lot.name}?</div>,
				okText: 'PROCEED',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					self.loading = true;
					self.$api.post(`/lots/:instance/un-allocate/${lot.id}`).then(() => {
						self.loading = false;
						self.$message.success('Lot unassigned successfully!')
						self.$store.commit("UPDATE_LOTS", {
							...lot,
							allocatedTo: null,
							allocationExpiresAt: null,
							status: 'available',
						});
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error occurred while unAssigning lot. Please try again!`))
						}
					})
				},
			});
		},

		onUnassignUnit(unit) {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign unit`)
			}
			if (unit.salesStatus !== 'allocated' && !unit.allocatedTo) return;

			let self = this
			this.$confirm({
				title: 'Unassign Unit',
				content: h => <div>Are you sure you want to unassign unit {unit.unitNumber}?</div>,
				okText: 'PROCEED',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					self.loading = true;
					self.$api.post(`/units/:instance/un-allocate/${unit.id}`).then(() => {
						self.loading = false;
						self.$message.success('Unit unassigned successfully!')
						self.$store.commit("UPDATE_UNITS", {
							...unit,
							allocatedTo: null,
							allocationExpiresAt: null,
							salesStatus: 'available',
						});
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error occurred while unAssigning unit. Please try again!`))
						}
					})
				},
			});
		},

		onUnassignAddOn(addOn, type = "parking") {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to unassign ${type}`)
			}
			if (addOn.status !== 'allocated' && !addOn.allocatedTo) return;

			let self = this
			this.$confirm({
				title: `Unassign ${type}`,
				content: h => <div>Are you sure you want to unassign {type} {addOn.name}?</div>,
				okText: 'PROCEED',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					self.loading = true;
					self.$api.post(`/units/:instance/unallocate-addons/${type}/${addOn.id}`).then(() => {
						self.loading = false;
						self.$message.success(`Selected ${type} unassigned successfully!`)
						self.$store.dispatch('FETCH_ADD_ONS')
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error occurred while unAssigning ${type}. Please try again!`))
						}
					})
				},
			});
		},

		onCommissionStructureUpdate() {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to update commission structure.`)
			}
			let self = this;

			this.$refs.commissionStructure.validate((valid) => {
				if (valid) {
					self.loading = true;
					let url = `/units/:instance/bulk-apply-commission-structure`;

					let payload = {
						commissionStructure: { commissionAmount: self.commissionStructure.commissionAmount, commissionType: self.commissionStructure.commissionType },
						buildingType: 'condounits'
					}
					if (self.selectedLot) {
						delete payload.buildingType
						url = `/lots/:instance/${self.selectedLot.siteplan}/${self.selectedLot.id}`
					} else if (self.selectedUnit) {
						url = `/units/:instance/${self.selectedUnit.id}`
					} else if (self.selectedAddOn) {
						url = `/units/:instance/apply-commission-structure-addons/${self.selectedAddOn.id}`
					} else if (self.selectedTab === 'lots' && self.selectedLots && self.selectedLots.length) {
						delete payload.buildingType
						payload.ids = self.selectedLots;
						url = `/lots/:instance/bulk-apply-commission-structure`
					} else if (self.selectedTab === 'units' && self.selectedUnits && self.selectedUnits.length) {
						payload.ids = self.selectedUnits;
					} else if (self.selectedTab === 'parking') {
						payload.ids = self.selectedParking;
						url = `units/:instance/bulk-apply-commission-structure-addons/parking`
					} else if (self.selectedTab === 'lockers') {
						payload.ids = self.selectedLockers;
						url = `units/:instance/bulk-apply-commission-structure-addons/lockers`
					} else if (self.selectedTab === 'bikeRacks') {
						payload.ids = self.selectedBikeRacks;
						url = `units/:instance/bulk-apply-commission-structure-addons/bikeRacks`
					}

					self.$api[(self.selectedUnit || self.selectedLot) ? 'put' : 'post'](url, payload)
						.then(({ data }) => {
							self.loading = false;
							if (self.selectedTab === 'lots') {
								self.$message.success(`${self.selectedUnit ? 'Lot' : 'Lots'} Commission Structure updated successfully!`)
							} else if (self.selectedTab === 'units') {
								self.$message.success(`${self.selectedUnit ? 'Unit' : 'Units'} Commission Structure updated successfully!`)
							} else {
								self.$message.success(`${self.selectedAddOn ? self.addOn[self.selectedTab] : self.addOns[self.selectedTab]} Commission Structure updated successfully!`)
							}
							if (self.selectedLot) {
								self.$store.commit("SET_PROP", {
									where: ["lots", self.selectedLot.id],
									what: {
										...self.selectedLot,
										commissionStructure: { ...data.commissionStructure }
									},
								});
								self.$store.commit("UPDATE_LOTS", {
									...self.selectedLot,
									commissionStructure: { ...data.commissionStructure }
								});
								self.selectedLot = null;
								self.$forceUpdate();
							} else if (self.selectedLots && self.selectedLots.length) {
								self.$store.dispatch('FETCH_UPDATED_DATA')
								self.checkAllLots = false
								self.indeterminateLots = false
								self.selectedLots = [];
							} else if (self.selectedUnit) {
								self.$store.commit("SET_PROP", {
									where: ["units", self.selectedUnit.id],
									what: {
										...self.selectedUnit,
										commissionStructure: { ...data.commissionStructure }
									},
								});
								self.$store.commit("UPDATE_UNITS", {
									...self.selectedUnit,
									commissionStructure: { ...data.commissionStructure }
								});
								self.selectedUnit = null;
							} else if (self.selectedUnits && self.selectedUnits.length) {
								self.$store.dispatch('FETCH_UPDATED_DATA')
								self.checkAllUnits = false
								self.indeterminateUnits = false
								self.selectedUnits = [];
							} else {
								self.selectedAddOn = null;
								self.selectedParking = [];
								self.selectedLockers = [];
								self.selectedBikeRacks = [];
								self.$store.dispatch('FETCH_ADD_ONS')
							}
							self.commissionModalVisible = false;
						})
						.catch((err) => {
							self.loading = false;
							if (err?.response?.status !== 400) {
								self.$message.error(self.$err(err, `Error occurred on commission structure change. Please try again!`))
							}
						})
				} else {
					return
				}
			})
		},

		resetCommissionStructure() {
			if (this.$p < 40) {
				return this.$message.error(`You do not have permission to update commission structure.`)
			}

			if (!this.selectedCommissionStructure || !this.selectedCommissionStructure.commissionAmount) {
				return this.$message.error('No General Commission Structure found. Please add first.')
			}

			if (this.selectedLot && this.selectedLot.commissionStructure) {
				if (this.selectedLot.commissionStructure.commissionAmount === this.lotGeneralCommissionStructure.commissionAmount && this.selectedLot.commissionStructure.commissionType === this.lotGeneralCommissionStructure.commissionType) {
					return this.$message.warn('Current Commission Structure is already a General Structure.')
				}
			} else if (this.selectedUnit && this.selectedUnit.commissionStructure) {
				if (this.selectedUnit.commissionStructure.commissionAmount === this.unitGeneralCommissionStructure.commissionAmount && this.selectedUnit.commissionStructure.commissionType === this.unitGeneralCommissionStructure.commissionType) {
					return this.$message.warn('Current Commission Structure is already a General Structure.')
				}
			} else if (this.selectedAddOn && this.selectedAddOn.commissionStructure) {
				if (this.selectedAddOn.commissionStructure.commissionAmount === this.addOnGeneralCommissionStructure.commissionAmount && this.selectedAddOn.commissionStructure.commissionType === this.addOnGeneralCommissionStructure.commissionType) {
					return this.$message.warn('Current Commission Structure is already a General Structure.')
				}
			}

			this.loading = true;
			this.commissionStructure.commissionType = this.selectedCommissionStructure.commissionType;
			this.commissionStructure.commissionAmount = this.selectedCommissionStructure.commissionAmount;
			setTimeout(() => {
				this.onCommissionStructureUpdate();
			}, 1000)
		},

		displayPremiums(unit) {
			let premiums = []
			if (unit.packages[0] && unit.packages[0].other && unit.packages[0].other.premiums) {
				Object.values(unit.packages[0].other.premiums).forEach(x => {
					if (x.active) {
						premiums.push(x)
					}
				})
			}
			return premiums
		},

		toggleSelection(id, allItemsArray, selectionArrayFlag, indeterminateFlag, checkAllFlag) {
			if (!this[selectionArrayFlag].includes(id)) {
				this[selectionArrayFlag].push(id);
			} else {
				let index = this[selectionArrayFlag].findIndex(x => x == id);
				this[selectionArrayFlag].splice(index, 1);
			}
			if (this[selectionArrayFlag].length == 0) {
				this[indeterminateFlag] = false;
				this[checkAllFlag] = false;
			}
			if (this[selectionArrayFlag].length < allItemsArray.length && this[selectionArrayFlag].length != 0) {
				this[indeterminateFlag] = true;
			}
			if (this[selectionArrayFlag].length == allItemsArray.length) {
				this[indeterminateFlag] = false;
				this[checkAllFlag] = true;
			}
		},

		toggleAllSelection(e, allItemsArray, selectionArrayFlag, indeterminateFlag, checkAllFlag) {
			if (e.target.checked) {
				this[indeterminateFlag] = false;
				this[checkAllFlag] = true;
				allItemsArray.forEach(item => {
					this[selectionArrayFlag].push(item.id);
				});
			} else {
				this[checkAllFlag] = false;
				this[indeterminateFlag] = false;
				this[selectionArrayFlag] = [];
			}
		},

		getAssigneeName(id) {
			const user = this.realtors.find(r => r.id === id)
			if (user) {
				return user.firstName + " " + user.lastName
			}
			return 'N/A'
		},

		disabledDate(current) {
			// Can not select days before today and today
			return current && current < moment().endOf('day');
		},

		openFilter() {
			this.$store.commit("OPEN_FILTER");
		},

		updateScrollVisibility() {
			let scrollContent = document.querySelector('.price-div');

			if (scrollContent) {
				if (scrollContent.offsetWidth < scrollContent.scrollWidth) {
					this.showScrollArrows = true;
				} else {
					this.showScrollArrows = false;
				}
			}
		},

		scrollLeft(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: -scrollSize,
				behavior: "smooth",
			});
		},

		scrollRight(id) {
			const scrollableArea = document.getElementById(id);
			const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
			scrollableArea.scrollBy({
				left: scrollSize,
				behavior: "smooth",
			});
		},
		changeSelect(type) {
			if (this.selectType[0] == "total") this.selectType = [];
			if (type != "total") {
				if (this.selectType.includes(type)) {
					let index = this.selectType.findIndex((x) => x == type);
					this.selectType.splice(index, 1);
				} else {
					this.selectType.push(type);
				}
			} else {
				this.selectType = ["total"];
			}
			if (this.selectType.length == 0 || this.selectType.length == 8)
				this.selectType = ["total"];
		},

		getModelType(id) {
            let types = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;

                    if (
                        this.lotUnits[id] &&
                        !types.includes(this.lotUnits[id].unitGroup.type)
                    ) {
                        types.push(this.lotUnits[id].unitGroup.type);
                    }
                });
                if (types.length > 1) {
                    types = "Various";
                } else if (types.length == 1) {
                    types = types[0];
                }
            } else {
                return (types = "N/A");
            }
            return types;
        },

		getFrontage(id) {
            let frontage = [];
            if (!this.storeLots[id].units.length) {
                return "N/A";
            } else {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;
                    if (
                        this.lotUnits[id] &&
                        !frontage.includes(this.lotUnits[id].unitGroup.size)
                    ) {
                        frontage.push(this.lotUnits[id].unitGroup.size);
                    }
                });
                if (frontage.length == 1) {
                    return frontage[0];
                } else if (frontage.length > 1) {
                    let min = Math.min(...frontage);
                    let max = Math.max(...frontage);
                    return `${min}-${max}`;
                }
            }
        },

		resetAllData() {
			this.checkAllLots = false
			this.checkAllUnits = false
			this.checkAllParking = false
			this.checkAllLockers = false
			this.checkAllBikeRacks = false

			this.indeterminateLots = false
			this.indeterminateUnits = false
			this.indeterminateParking = false
			this.indeterminateLockers = false
			this.indeterminateBikeRacks = false

			this.selectedLots = []
			this.selectedUnits = []
			this.selectedParking = []
			this.selectedLockers = []
			this.selectedBikeRacks = []

			this.selectedLot = null;
			this.selectedUnit = null;
			this.selectedAddOn = null;
		}
	},

	mounted() {
		if(this.instance.productType === 'lowrise') {
			this.selectedTab = 'lots'
		}
		let scrollContent = document.querySelector('.price-div');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if (scrollContent) {
			this.resizeObserver.observe(scrollContent);
		}

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
}
</script>

<style>
</style>
